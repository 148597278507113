<template>
  <div>
    <bread-crumb :items="items"></bread-crumb>
    <Map />
    <h2 class="text-center display-1 my-5">CONTACT US</h2>

    <v-container fluid class="px-15">
      <v-row>
        <v-col cols="12" md="4" lg="5" order="3" order-sm="2">
          <contact-form category="general" />
        </v-col>

        <v-col cols="12" md="7" lg="7" order="3" order-sm="2">
          <v-card class="pa-5 grey lighten-4 text-center">
            <h2 class>HEADQUATER:</h2>
            <div class="mt-4">
              <b>ADO</b><br />
              <span
                >68b, EKSUTH ROAD,<br />
                Opp CRUNCHIES EATERY. <br />
                ADO EKITI
              </span>

              <b class="d-block mt-2">ENQUIRIES </b>
              <span class="d-block">Bookstore and Hall : 07039301287</span>
              <span class="d-block">Hub : 08134072342</span>
            </div>

            <h3 class="mt-6">BRANCHES</h3>

            <v-row class="mt-2">
              <v-col>
                <b>FUOYE</b><br />
                <span>Federal University Oye</span><br />
                <span>Beside Social Sciences</span><br />
                <span>Oye-Are Road, Oye Ekiti</span><br />
                <span>Ekiti state</span>
              </v-col>

              <v-col>
                <b>EKSU</b><br />
                <span>Ekiti state University</span><br />
                <span>Beside Health Center,</span><br />
                <span>Iworoko Road,</span>
                <span>Ado Ekiti</span>
              </v-col>

              <!-- <v-col>
                <b>ORIOMI</b><br>
                <span>Ekiti state Library</span><br>
                <span>Oke Oriomi Street,</span>
                <span>Ado Ekiti</span>
              </v-col> -->
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb.vue";
import ContactForm from "@/components/ContactForm.vue";
import Map from "@/components/Map.vue";
export default {
  name: "Contact",
  components: { BreadCrumb, Map, ContactForm },

  data() {
    return {
      items: [
        {
          text: "Home",
          disabled: false,
          link: "Home",
        },
        {
          text: "Contact",
          disabled: true,
          link: "Contact",
        },
      ],
    };
  },
};
</script>
